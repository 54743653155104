.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  background: none;

  &_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &_links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    &_item {
      font-size: 20px;
      font-family: Montserrat;
    }
  }

  &_contacts {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #567e5f;
      background: #c1d4b2;
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }

  &_user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      width: 100%;

      section {
        font-size: 10px;
        position: relative;
        border-radius: 8px;
        background: #c2ddb2;
        padding: 3px 5px;

        span {
          bottom: 0;
          right: 0;
          background: #567e5f;
          position: absolute;
          font-size: 3px;
          color: white;
          border-radius: 5px;
          padding: 2px 3px;
        }
      }

      > :nth-child(2) span {
        background: #e9a907;
      }
      > :nth-child(3) span {
        background: #1291d9;
      }
    }
  }
}
