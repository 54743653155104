.profile {
  display: flex;
  padding: 50px;
  gap: 35px;

  &_navigation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100vh - 190px);
    min-width: 340px;
    overflow: auto;

    &_item {
      display: flex;
      align-items: center;
      padding: 24px 20px;
      gap: 15px;
      background: #f2f2f2;
      width: 320px;
      border-radius: 4px;
      color: #000;
      cursor: pointer;
      position: relative;

      span {
        position: absolute;
        border-radius: 4px;
        background: #b2b2b0;
        width: 65px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: 0;
        color: white;
      }

      svg {
        color: #b2b2b0;
      }
    }

    &_item_active {
      width: 320px;
      background: #dfdfdf;
      display: flex;
      align-items: center;
      padding: 24px 20px;
      gap: 15px;
      border-radius: 4px;
      color: #000;
      cursor: pointer;

      svg {
        color: #b2b2b0;
      }
    }

    > :first-child span {
      background: #4ca11f;
    }

    > :nth-child(2) span {
      background: #e9a907;
    }
    > :nth-child(3) span {
      background: #1291d9;
    }
  }

  &_content {
    height: calc(100vh - 190px);
    width: 100%;
    padding: 10px;
    background: #f2f2f2;
    border-radius: 4px;
    overflow: auto;
  }

  &_transaction {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &_title {
      font-size: 24px;
    }

    &_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #dfdfdf;
      border-radius: 8px;
      padding: 4px;

      div {
        width: calc(50% - 5px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        border-radius: 4px;
      }

      &_item_active {
        background: white;
      }
    }

    &_form {
      display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      flex-direction: column;

      h1 {
        font-size: 20px;
      }
    }

    &_adress_input {
      width: 100%;

      input {
        width: 100%;
        height: 30px;
        background: white;
        border-radius: 4px;
        padding: 10px;
      }
    }

    &_currency {
      display: flex;
      width: 50%;
    }

    &_select {
      height: 35px;
      padding: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: white;
      position: relative;

      &_value {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;

        svg {
          font-size: 24px;
        }
      }

      &_list {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        border-radius: 4px;
        width: 100%;
        background: white;

        > :first-child {
          color: #4ca11f;
        }

        > :nth-child(2) {
          color: #e9a907;
        }
        > :nth-child(3) {
          color: #1291d9;
        }
      }

      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        gap: 10px;
        cursor: pointer;
        color: #000;

        &:hover {
          background: #dfdfdf;
        }
      }
    }

    &_sendButton {
      width: 140px;
      height: 40px;
      padding: 0;
      font-weight: 400;
      border-radius: 4px;
      background: var(--red-gradient);
      color: white;
    }

    &_amount_input {
      display: flex;
      width: 50%;
      gap: 20px;

      input {
        width: calc(100% - 90px);
        height: 30px;
        background: white;
        padding: 10px;
        border-radius: 4px;
      }

      p {
        width: 90px;
        height: 30px;
        background: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_amount {
      display: flex;
      align-items: center;
      width: 147px;
      background: white;
      height: 30px;
      padding: 10px;
      gap: 10px;
      font-weight: 500;
    }
  }

  &_transaction_history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    width: 100%;
    height: 100%;
    overflow: auto;

    > h1 {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;
    }
    &_table {
      display: flex;
      flex-direction: column;
      display: flex;

      &_head,
      &_body > div {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 1fr;
        gap: 20px;
      }

      &_body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0;

        p {
          display: flex;
          align-items: center;
          gap: 5px;
          height: 35px;
          padding: 10px;
          border-radius: 4px;
          background: white;
        }
      }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal_content {
      max-width: 500px;
      background: white;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      padding: 80px;
      position: relative;
      gap: 10px;

      h1 {
        font-size: 30px;
        font-weight: 600;
      }

      > p {
        font-size: 20px;
      }

      button {
        font-weight: 400;
      }
    }

    &_amount {
      height: 85px;
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #eaeaea;
      border-radius: 4px;
    }
  }
}
