.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-color);
  width: 100%;
  height: 100vh;

  &Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    padding: 80px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    gap: 10px;
  }
  &Form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
  }
}
